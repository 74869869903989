import React, { useState } from "react";
import { useSelector } from "react-redux";
import { plansSelector } from "../features/plans-slice";
import { RootState, useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { CustomerProfile } from "../utils/types";
import { Button } from "./button";
import { Card } from "./card";
import Modal, { ModalProps } from "./modal";
import { ReachPlan } from "../utils/types";
import { useErrorHandler } from "./error-snackbar";
import classNames from "classnames";
import { changePlan, fetchProfile } from "../features/profile-slice";
import { CustomerStatus } from "../utils/types/customer";
import { usePermissions } from "../features/hooks/use-permissions";
import { useSuccessModal } from "./success-modal";
import { getBytes } from "../utils/data-conversion";
import { Link } from "gatsby";
import { isPostActivationState } from "../utils/helpers";

export type ReachPlanCardProps = CustomerProfile;

function getTotalLineCost(plan: ReachPlan, lines: number) {
    const actualPrice = ((100 - plan.discountPctg) * plan.baseLinePrice) / 100;

    let prices = 0;
    const additionalLines = lines - 1;
    if (plan.addLineSplitPrice) {
        for (let i = 1; i <= additionalLines; i++) {
            prices = prices + plan.addLineSplitPrice[`${i}`];
        }
    } else {
        prices = additionalLines * plan.additionalLinePrice;
    }
    return actualPrice + prices;
}

function ChangePlanModal(props: { customerProfile: CustomerProfile } & ModalProps) {
    const { customerProfile, ...rest } = props;

    const plans = useSelector(plansSelector.selectAll);

    const [selectedPlanName, setSelectedPlanName] = useState(customerProfile.reachPlan!.name);
    const [showConfirmation, setConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);

    const selectedPlan = useSelector((state: RootState) => plansSelector.selectById(state, selectedPlanName))!;

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();
    const showSuccessModal = useSuccessModal();

    const onSubmit = async (immediateEffect: boolean) => {
        setLoading(true);
        try {
            await unwrap(
                changePlan({
                    customerId: customerProfile.id,
                    newReachPlanId: selectedPlanName,
                    immediateEffect,
                })
            );
            await dispatch(fetchProfile(customerProfile.id));
            if (immediateEffect) {
                showSuccessModal(`Current plan changed to ${selectedPlanName}`);
            } else {
                showSuccessModal(`Current plan will be changed to ${selectedPlanName} in the next billing cycle`);
            }
        } catch (error) {
            console.log(error);
            handleError(error);
        }
        rest.onHide();
        setLoading(false);
    };

    if (showConfirmation) {
        const totalLines = customerProfile.groupLines.filter((customer) => isPostActivationState(customer.status))
            .length;

        const currentPlanCost = getTotalLineCost(customerProfile.reachPlan!, totalLines);
        const newPlanCost = getTotalLineCost(selectedPlan, totalLines);

        const isDowngrade =
            getBytes(customerProfile.reachPlan!.planData, customerProfile.reachPlan!.dataUnit) >
            getBytes(selectedPlan.planData, selectedPlan.dataUnit);

        return (
            <Modal size="sm" {...rest}>
                <div className="w-100 d-flex justify-content-between align-items-end">
                    <div>
                        <div className="font-family-bold" style={{ fontSize: 16 }}>
                            Current Plan
                        </div>
                        <div className="font-family-semibold" style={{ fontSize: 12 }}>
                            {customerProfile.reachPlan!.planData}
                            {customerProfile.reachPlan!.dataUnit} for {totalLines} + Tax
                        </div>
                    </div>
                    <div className="font-family-semibold" style={{ fontSize: 12 }}>
                        ${currentPlanCost.toFixed(2)}
                    </div>
                </div>
                <div className="divider my-3" />
                <div className="w-100 d-flex justify-content-between align-items-end mb-3">
                    <div>
                        <div className="font-family-bold" style={{ fontSize: 16 }}>
                            New Plan
                        </div>
                        <div className="font-family-semibold" style={{ fontSize: 12 }}>
                            {selectedPlan.planData}
                            {selectedPlan.dataUnit} for {totalLines} + Tax
                        </div>
                    </div>
                    <div className="font-family-semibold" style={{ fontSize: 12 }}>
                        ${newPlanCost.toFixed(2)}
                    </div>
                </div>
                <Button color="secondary" fullWidth onClick={() => onSubmit(true)} loading={loading}>
                    {isDowngrade ? "Downgrade Now" : "Upgrade Now"}
                </Button>
                {customerProfile.status === CustomerStatus.ACTIVE && (
                    <div
                        className={classNames("text-cta mt-2", { disabled: loading })}
                        onClick={() => !loading && onSubmit(false)}>
                        {isDowngrade ? "Downgrade later" : "Upgrade later"}
                    </div>
                )}
            </Modal>
        );
    }

    return (
        <Modal size="sm" {...rest}>
            {plans.map((plan, index) => {
                const actualPrice = ((100 - plan.discountPctg) * plan.baseLinePrice) / 100;

                return (
                    <div key={plan.name} className="w-100">
                        {index !== 0 && <div className="divider mb-3" />}
                        <div
                            className={classNames("d-flex justify-content-between align-items-center cursor-pointer", {
                                disabled: customerProfile.additionalLines + 1 > plan.maxLines,
                            })}
                            onClick={() =>
                                customerProfile.additionalLines + 1 <= plan.maxLines && setSelectedPlanName(plan.name)
                            }>
                            <div>
                                <div className="font-family-medium" style={{ fontSize: 16 }}>
                                    {plan.name}
                                </div>
                                <div className="font-family-bold">
                                    {plan.planData}
                                    {plan.dataUnit}{" "}
                                    <span className="font-family-semibold">
                                        {" "}
                                        - {plan.isUnlimited ? "Per line + uncapped 2G speed" : "Sharable data"}
                                    </span>
                                    <div className="mb-3">
                                        <span className="font-family-bold">${actualPrice}</span>
                                        <span className="font-family-semibold">/Month (+ taxes & fees)</span>
                                    </div>
                                </div>
                            </div>
                            {plan.name === selectedPlanName && (
                                <div
                                    className="bg-primary text-center"
                                    style={{ borderRadius: 12, width: 24, height: 24 }}>
                                    <span className="reach-cs-tick text-white" style={{ verticalAlign: "middle" }} />
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
            <Button
                color="secondary"
                fullWidth
                disabled={customerProfile.reachPlan!.name === selectedPlanName}
                onClick={() => setConfirmation(true)}>
                Confirm
            </Button>
        </Modal>
    );
}

export default function ReachPlanCard(props: ReachPlanCardProps) {
    const { ...customerProfile } = props;

    const [showChangePlanModal, setShowChangePlanModal] = useState(false);

    const { hasOnePermission } = usePermissions();

    if (!customerProfile.reachPlan) {
        return null;
    }

    let numOfExtraLines = 0;
    if (customerProfile.extraLines > customerProfile.additionalLines) {
        numOfExtraLines = customerProfile.extraLines - customerProfile.additionalLines;
    }
    const lines = customerProfile.groupLines.length + numOfExtraLines;

    if (!hasOnePermission("user_management.reach_plan.read")) {
        return null;
    }

    return (
        <Card>
            <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                Plan details
            </div>
            <div className="font-family-bold my-2" style={{ fontSize: 16 }}>
                {customerProfile.reachPlan.name} {customerProfile.reachPlan.planData}
                {customerProfile.reachPlan.dataUnit} -{" "}
                <span className="font-family-medium">
                    for {lines} {lines > 1 ? "lines" : "line"}
                </span>
            </div>
            {customerProfile.isPrimary && (
                <Button
                    color="secondary"
                    className="col-10"
                    disabled={!hasOnePermission("user_management.reach_plan.change")}
                    onClick={() => setShowChangePlanModal(true)}>
                    Change Plan
                </Button>
            )}
            {customerProfile.isPrimary && isPostActivationState(customerProfile.status) && (
                <Link to={`/plan/history/${customerProfile.id}`} className="text-cta mt-3">
                    View details
                </Link>
            )}
            {showChangePlanModal && (
                <ChangePlanModal
                    customerProfile={customerProfile}
                    title="Change plan"
                    show={showChangePlanModal}
                    onHide={() => setShowChangePlanModal(false)}
                />
            )}
        </Card>
    );
}
