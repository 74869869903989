import React, { useEffect, useState } from "react";
import { navigate, Link } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "../utils/store";
import Layout from "../components/layout";
import { Card } from "../components/card";
import { profilesSelector } from "../features/profile-slice";
import branding from "../branding/branding.json";
import { useViewport, ViewportBreakpoint } from "../utils/viewport-context";
import {
    getBillingForecast,
    leaveReach,
    LeavingReachActions,
    selectDisconnectingCustomers,
    selectDisconnectingLaterCustomers,
    selectLeavingCustomers,
    selectPortoutCustomers,
} from "../features/leaving-reach-slice";
import { formatPhone, isPostActivationState } from "../utils/helpers";
import classNames from "classnames";
import { format, max } from "date-fns";
import { Button } from "../components/button";
import CustomerBadge from "../components/customer-badge";
import Modal from "../components/modal";
import { useForm } from "react-hook-form";
import { useErrorHandler } from "../components/error-snackbar";
import { LeaveReachReq } from "../utils/types";
import PayInAdvanceModal from "../components/pay-in-advance-modal";
import CustomerVerification from "../components/customer-verification";
import Select from "../components/select";
import { usePermissions } from "../features/hooks/use-permissions";

export interface LeavingReachSummaryProps extends RouteComponentProps {
    customerId: string;
}

enum ModalType {
    ACCOUNT_SWAP,
    LEAVING_REACH,
}

interface FormData {
    reason: string;
}

export default function LeavingReachSummary(props: LeavingReachSummaryProps) {
    const { customerId } = props;

    const customerProfile = useAppSelector((state) => profilesSelector.selectById(state, customerId));

    const leavingCustomers = useAppSelector(selectLeavingCustomers(customerId));
    const portOutCustomers = useAppSelector(selectPortoutCustomers(customerId));
    const disconnectingCustomers = useAppSelector(selectDisconnectingCustomers(customerId));
    const disconnectingLaterCustomers = useAppSelector(selectDisconnectingLaterCustomers(customerId));

    const [modalType, setModalType] = useState<ModalType>();
    const [amountToPay, setAmountToPay] = useState<number>();
    const [showPayInAdvance, setShowPayInAdvance] = useState(false);
    const [showCustomerVerification, setShowCustomerVerification] = useState(false);
    const [disconnectionReason, setDisconnectionReason] = useState("");
    const [nextBillCycleSelected, setNextBillCycleSelected] = useState(false);

    const { getLimitations } = usePermissions();

    const creditLimitations = getLimitations("user_management.leaving_reach.disconnect");

    const { handleSubmit, formState, register, errors } = useForm({
        mode: "onBlur",
    });

    const { width } = useViewport();

    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const handleError = useErrorHandler();

    const dates = leavingCustomers.map((item) =>
        item.laterDisconnectionDate != null ? new Date(item.laterDisconnectionDate) : new Date()
    );

    const endDate = max(dates);

    useEffect(() => {
        async function fetchForecast() {
            try {
                console.log(endDate);
                const res = await unwrap(
                    getBillingForecast({
                        customerId,
                        endDate: format(endDate, "yyyy-MM-dd"),
                    })
                );
                setAmountToPay(res.amountToPay);
                setNextBillCycleSelected(res.nextBillCycle);
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
        fetchForecast();
    }, []);

    if (!customerProfile) {
        navigate(`/profile/${customerId}`);
        return null;
    }

    if (leavingCustomers.length === 0) {
        navigate(`/disconnections/${customerId}`);
    }

    const primaryCustomer = customerProfile.groupLines.find((customer) => customer.isPrimary)!;

    const onSubmit = () => {
        const activeCustomers = customerProfile.groupLines.filter((customer) => isPostActivationState(customer.status));
        if (
            leavingCustomers.some((res) => res.customer.isPrimary) &&
            leavingCustomers.length !== activeCustomers.length
        ) {
            setModalType(ModalType.ACCOUNT_SWAP);
        } else {
            setModalType(ModalType.LEAVING_REACH);
        }
    };

    const onModalSubmit = async (data: FormData) => {
        if (!showCustomerVerification && modalType === ModalType.LEAVING_REACH) {
            setDisconnectionReason(data.reason);
            setShowCustomerVerification(true);
            return;
        }

        if (modalType === ModalType.LEAVING_REACH) {
            // call API and proceed to profile
            const payload: LeaveReachReq = { reason: data.reason, customers: {} };
            for (const entry of leavingCustomers) {
                payload.customers[entry.customerId] = {
                    type: entry.type,
                    laterDisconnectionDate: entry.laterDisconnectionDate,
                };
            }
            try {
                await unwrap(
                    leaveReach({
                        customerId: primaryCustomer.id,
                        payload,
                    })
                );
                navigate(`/profile/${customerId}`);
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        } else {
            dispatch(LeavingReachActions.reasonUpdated(data.reason));
            navigate(`/disconnections/account-swap/${primaryCustomer.id}`);
        }
        setModalType(undefined);
    };

    return (
        <Layout title={`${customerProfile.firstName} ${customerProfile.lastName} - Disconnections`}>
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to={`/disconnections/${customerId}`}>
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                </div>
                <div className="mb-3 font-family-semibold d-md-flex justify-content-between">
                    <div>
                        {customerProfile.firstName} {customerProfile.lastName}{" "}
                        <CustomerBadge isPrimary={customerProfile.isPrimary} />
                    </div>
                    <div>{customerProfile.emailId}</div>
                </div>

                <h2>Disconnections</h2>
                <Card>
                    <div className="font-family-semibold" style={{ fontSize: 18 }}>
                        Estimated bill amount:{" "}
                        {amountToPay == null ? (
                            <span className="spinner-border spinner-border-sm mb-1 text-primary" />
                        ) : (
                            <span className="font-family-bold text-center">
                                ${amountToPay}{" "}
                                {nextBillCycleSelected && (
                                    <div className="font-family-regular" style={{ fontSize: 12 }}>
                                        Estimated Bill Amount is for current bill cycle
                                    </div>
                                )}
                                <div className="text-cta" onClick={() => setShowPayInAdvance(true)}>
                                    Pay in advance
                                </div>
                            </span>
                        )}
                    </div>
                    {portOutCustomers.length > 0 && (
                        <>
                            <h2 className="divider">Port-out</h2>
                            {portOutCustomers.map((item, index) => (
                                <div className="w-100 pb-2" key={item.customerId}>
                                    {index !== 0 && <div className="divider mb-2" />}
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="font-family-semibold">
                                                {item.customer.firstName} {item.customer.lastName}
                                            </div>
                                            <div className="font-family-medium">
                                                {formatPhone(item.customer.reachNumber)}
                                            </div>
                                            <div>
                                                <CustomerBadge isPrimary={item.customer.isPrimary} />
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <div
                                                className="text-cta"
                                                onClick={() =>
                                                    dispatch(LeavingReachActions.customerRemoved(item.customerId))
                                                }>
                                                Remove
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={classNames("mt-3 justify-content-between", {
                                            "d-flex": width >= ViewportBreakpoint.MD,
                                        })}>
                                        <div>
                                            <div>Billing zip code</div>
                                            <div className="font-family-semibold">{item.customer.zipcode}</div>
                                        </div>
                                        <div>
                                            <div>PIN</div>
                                            <div className="font-family-semibold">{item.customer.zipcode}</div>
                                        </div>
                                        <div>
                                            <div>Account number</div>
                                            <div className="font-family-semibold">{item.customer.customerNumber}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                    {disconnectingCustomers.length > 0 && (
                        <>
                            <h2 className="divider">Disconnect now</h2>
                            {disconnectingCustomers.map((item, index) => (
                                <div className="w-100 pb-2" key={item.customerId}>
                                    {index !== 0 && <div className="divider mb-2" />}
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="font-family-semibold">
                                                {item.customer.firstName} {item.customer.lastName}
                                            </div>
                                            <div className="font-family-medium">
                                                {formatPhone(item.customer.reachNumber)}
                                            </div>
                                            <div>
                                                <CustomerBadge isPrimary={item.customer.isPrimary} />
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <div
                                                className="text-cta"
                                                onClick={() =>
                                                    dispatch(LeavingReachActions.customerRemoved(item.customerId))
                                                }>
                                                Remove
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                    {disconnectingLaterCustomers.length > 0 && (
                        <>
                            <h2 className="divider">Disconnect later</h2>
                            {disconnectingLaterCustomers.map((item, index) => (
                                <div className="w-100 pb-2" key={item.customerId}>
                                    {index !== 0 && <div className="divider mb-2" />}
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="font-family-semibold">
                                                {item.customer.firstName} {item.customer.lastName}
                                            </div>
                                            <div className="font-family-medium">
                                                {formatPhone(item.customer.reachNumber)}
                                            </div>
                                            <div>
                                                <CustomerBadge isPrimary={item.customer.isPrimary} />
                                            </div>
                                        </div>
                                        {item.laterDisconnectionDate && width >= ViewportBreakpoint.MD && (
                                            <div>
                                                <div>Disconnection date</div>
                                                <div className="font-family-semibold">
                                                    {format(new Date(item.laterDisconnectionDate), "MMM dd, yyyy")}
                                                </div>
                                            </div>
                                        )}
                                        <div className="text-right">
                                            <div
                                                className="text-cta"
                                                onClick={() =>
                                                    dispatch(LeavingReachActions.customerRemoved(item.customerId))
                                                }>
                                                Remove
                                            </div>
                                        </div>
                                    </div>
                                    {item.laterDisconnectionDate && width < ViewportBreakpoint.MD && (
                                        <div className="mt-3">
                                            <div>Disconnection date</div>
                                            <div className="font-family-semibold">
                                                {format(new Date(item.laterDisconnectionDate), "MMM dd, yyyy")}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}

                    <Button color="secondary" className="mt-3 align-self-center col-12 col-md-4" onClick={onSubmit}>
                        Continue
                    </Button>
                </Card>
            </div>

            <Modal
                size="sm"
                title={
                    modalType === ModalType.ACCOUNT_SWAP
                        ? "To proceed, you must swap the primary account"
                        : "Continue with the selection?"
                }
                show={modalType != null}
                onHide={() => setModalType(undefined)}
                titleClassName={classNames({ disabled: showCustomerVerification })}>
                <div
                    className={classNames("w-100 d-flex flex-column align-items-center", {
                        // disabled: showCustomerVerification,
                    })}
                    // style={{ pointerEvents: showCustomerVerification ? "none" : undefined }}
                >
                    <Select
                        className="col-12 px-0 mb-3"
                        register={register({
                            required: {
                                value: true,
                                message: "Description is required",
                            },
                        })}
                        name="reason"
                        showError={errors.reason != null}
                        defaultValue={disconnectionReason}
                        onChange={(e) => setDisconnectionReason(e.target.value)}
                        errorMessage={errors.reason?.message}>
                        <option value="">Select disconnection reason</option>
                        {creditLimitations?.descriptions.value.map((value) => (
                            <option value={value} key={value}>
                                {value}
                            </option>
                        ))}
                    </Select>
                    {!showCustomerVerification && (
                        <Button
                            color="secondary"
                            fullWidth
                            loading={formState.isSubmitting}
                            onClick={handleSubmit(onModalSubmit)}>
                            Confirm
                        </Button>
                    )}
                </div>
                {showCustomerVerification && (
                    <CustomerVerification onSubmit={handleSubmit(onModalSubmit)} customerProfile={customerProfile} />
                )}
            </Modal>

            <PayInAdvanceModal
                title="Pay in advance"
                show={showPayInAdvance}
                onHide={() => setShowPayInAdvance(false)}
                customerProfile={customerProfile}
                billingDate={endDate}
            />
        </Layout>
    );
}
