import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../components/layout";
import { Link, navigate } from "gatsby";
import { Card } from "../components/card";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useUnwrapAsyncThunk } from "../utils/store";
import { useViewport, ViewportBreakpoint } from "../utils/viewport-context";
import networkQuickActionIcon from "../images/networkQuickAction@2x.png";
import dataManagementQuickActionIcon from "../images/dataManagementQuickAction@2x.png";
import billingQuickActionIcon from "../images/billingQuickAction@2x.png";
import userManagementQuickActionIcon from "../images/userManagementQuickAction@2x.png";
import profilePlaceholder from "../images/profilePlaceholder.png";
import { formatPhone, getPaymentFailureMessageForCode, isPostActivationState } from "../utils/helpers";
import {
    fetchProfile,
    getActivation,
    profilesSelector,
    ProfileState,
    profileStateSelector,
} from "../features/profile-slice";
import { Button } from "../components/button";
import PaymentCards from "../components/payment-cards";
import { usePermissions } from "../features/hooks/use-permissions";
import ManageLines, { isCustomer } from "../components/manage-lines";
import { CustomerStatus, OperationsInProcess } from "../utils/types/customer";
import CardHoldModal from "../components/card-hold-modal";
import { customerSelectedForActivation } from "../features/activation-slice";
import LineInfoModal from "../components/line-info-modal";
import PaymentLinkCard from "../components/payment-link-card";
import ShipmentOrderCard from "../components/shipment-order-card";
import ActivationStatusCard from "../components/activation-status-card";
import EstimateBillCard from "../components/estimate-bill-card";
import ReachPlanCard from "../components/reach-plan-card";
import RecurringTopupModal from "../components/recurring-topup-modal";
import DataUsageCard from "../components/data-usage-card";
import RemoveTopupModal from "../components/remove-topup-modal";
import AddTopupModal from "../components/add-topup-modal";
import SimSwapModal from "../components/sim-swap-modal";
import TopupHistoryModal from "../components/topup-history-modal";
import NetworkResetModal from "../components/network-reset-modal";
import PaymentHistoryCard from "../components/payment-history-card";
import { currentBillSelector } from "../features/billing-slice";
import bestFitIcon from "../images/bestFitIcon.png";
import CurrentBillCard from "../components/current-bill-card";
import RegenerateBillModal from "../components/regenerate-bill-modal";
import ReferralCreditModal from "../components/referral-credit-modal";
import WaiveOffPaymentModal from "../components/waive-off-payment-modal";
import internationalRoamingIcon from "../images/internationalRoaming.png";
import internationalCallingIcon from "../images/internationalCalling.png";
import classNames from "classnames";
import NewNumberModal from "../components/new-number-modal";
import { isAfter } from "date-fns";
import CustomerActivityCard from "../components/customer-activity-card";
import { OrderStatus } from "../utils/types";
import DeviceDetailsCard from "../components/device-details-card";
import googleLoginIcon from "../images/googleLogin.png";
import appleLoginIcon from "../images/appleLogin.png";
import facebookLoginIcon from "../images/facebookLogin.png";
import emailLoginIcon from "../images/emailLogin.png";
import CustomerBadge from "../components/customer-badge";
import CustomerAccountInfo from "../components/customer-account-info";
import Modal from "../components/modal";
import VoicemailResetModal from "../components/voicemail-reset-modal";
import AddonChargeModal from "../components/add-on-charge-modal";
import OrderHistoryCard from "../components/order-history-card";
import { customersLoaded } from "../features/purchase-slice";
import PayInAdvanceModal from "../components/pay-in-advance-modal";
import InstantChargeModal from "../components/instant-charge-modal";
import MDNAlertSnackbar from "../components/mdn-alert-snackbar";
import { useErrorHandler } from "../components/error-snackbar";
import { useSuccessModal } from "../components/success-modal";
import { setZohoVisitorInfo } from "../components/zoho-chat-provider";
import TemporaryPasswordModal from "../components/temporary-password-modal";
import { format } from "date-fns/esm";
import HelpdeskCard from "../components/helpdesk-card";
import ToolsCard from "../components/tools-card";

export interface CustomerProfileProps extends RouteComponentProps {
    customerId: string;
}

enum ModalType {
    CardHold,
    SecondaryLine,
    RecurringTopup,
    RemoveTopup,
    AddTopup,
    SimSwap,
    TopupHistory,
    NetworkReset,
    RegenerateBill,
    ReferralCredit,
    WaiveOffPayment,
    NewNumber,
    Voicemail,
    AddonCharge,
    InstantCharge,
    PayInAdvance,
    RemoveCustomer,
    Primary,
    TemporaryPassword,
}

export default function CustomerProfile(props: CustomerProfileProps) {
    const { customerId } = props;
    const { width } = useViewport();
    const dispatch = useAppDispatch();
    const unwrap = useUnwrapAsyncThunk();
    const showSuccessModal = useSuccessModal();
    const handleError = useErrorHandler();

    const [modalType, setModalType] = useState<ModalType>();

    const customerProfile = useSelector((state: RootState) => profilesSelector.selectById(state, customerId));

    const { hasOnePermission } = usePermissions();

    const { profileState, error } = useSelector(profileStateSelector);

    const currentBill = useSelector(currentBillSelector(customerId));

    const searchParams = new URLSearchParams(props.location?.search);

    const xResponseCode = searchParams.get("x_response_code");
    const transId = searchParams.get("transId");
    const xBankResponseCode = searchParams.get("x_bank_response_code");

    useEffect(() => {
        if (transId && !xResponseCode) {
            showSuccessModal("Transaction was successful");
        } else if (xBankResponseCode) {
            handleError({ message: getPaymentFailureMessageForCode(xBankResponseCode) });
        }
    }, [xResponseCode, transId, xBankResponseCode]);

    useEffect(() => {
        setZohoVisitorInfo({
            customerId,
            MDN: customerProfile?.reachNumber,
        });
        async function onLoad() {
            await dispatch(fetchProfile(customerId));
            if (
                customerProfile?.status === CustomerStatus.ACTIVATION_IN_PROGRESS ||
                customerProfile?.status === CustomerStatus.PORTING_IN_PROGRESS
            ) {
                const res = await unwrap(getActivation(customerId));
                if (
                    res.status !== CustomerStatus.ACTIVATION_IN_PROGRESS &&
                    res.status !== CustomerStatus.PORTING_IN_PROGRESS
                ) {
                    await dispatch(fetchProfile(customerId));
                }
            }
        }
        onLoad();

        return () => {
            setZohoVisitorInfo({
                customerId: undefined,
                MDN: undefined,
            });
        };
    }, [customerId]);

    if (customerProfile?.isDraft) {
        dispatch(customersLoaded(customerProfile));
        navigate("/purchase/new");
        return null;
    }

    const primaryCustomer = customerProfile?.groupLines.find((customer) => customer.isPrimary);

    return (
        <Layout title={customerProfile ? `${customerProfile.firstName} ${customerProfile.lastName}` : ""}>
            {hasOnePermission("user_management.mdn_alert.read") && (
                <MDNAlertSnackbar latestMDNAlert={customerProfile?.latestMDNAlert} />
            )}
            <div className="container col-10 d-flex flex-column my-3 px-0">
                <div className="d-flex justify-content-between">
                    <Link className="text-cta mb-3" to="/customers">
                        <span className="reach-cs-arrow-left-solid" style={{ marginRight: 6, fontSize: 12 }} />
                        Back
                    </Link>
                    {profileState === ProfileState.LOADING && <div className="spinner-border text-primary" />}
                </div>
                {profileState === ProfileState.ERROR && <div className="text-error">{error}</div>}
                {customerProfile && (
                    <>
                        <Card>
                            <div className="w-100 row align-self-start mx-n2">
                                {width >= ViewportBreakpoint.MD && (
                                    <div className="col-md-4 col-xl-3 px-2">
                                        <img src={profilePlaceholder} />
                                        <div className="mt-2">
                                            <div>
                                                First Name <CustomerBadge isPrimary={customerProfile.isPrimary} />
                                            </div>
                                            <div className="font-family-semibold">{customerProfile.firstName}</div>
                                        </div>
                                        <div className="mt-2">
                                            <div>Last Name</div>
                                            <div className="font-family-semibold">{customerProfile.lastName}</div>
                                        </div>
                                    </div>
                                )}
                                <div className="col-12 col-md-4 col-xl-3 px-2" style={{ overflowWrap: "break-word" }}>
                                    <div className="mt-2">
                                        <div>Phone</div>
                                        <div className="font-family-semibold">
                                            {customerProfile.reachNumber
                                                ? formatPhone(customerProfile.reachNumber)
                                                : customerProfile.primaryNumber
                                                ? formatPhone(customerProfile.primaryNumber)
                                                : "-"}
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div>Email ID</div>
                                        <div className="font-family-semibold">{customerProfile.emailId}</div>
                                    </div>
                                    <div className="mt-2">
                                        <div>Billing zip code</div>
                                        <div className="font-family-semibold">{customerProfile.zipcode}</div>
                                    </div>
                                    <div className="mt-2">
                                        <div>Billing address</div>
                                        <div className="font-family-semibold" style={{ width: 200 }}>
                                            {Object.entries(customerProfile.addresses[0])
                                                .map(
                                                    ([key, value]) =>
                                                        key !== "name" &&
                                                        key !== "type" &&
                                                        key !== "zip" &&
                                                        key !== "phone" &&
                                                        value
                                                )
                                                .filter(Boolean)
                                                .join(", ")}
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div>Account number</div>
                                        <div className="font-family-semibold">
                                            {customerProfile.customerNumber ?? "-"}
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div>Pin</div>
                                        <div className="font-family-semibold">{customerProfile.zipcode}</div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-xl-3 mt-2 mt-md-0 px-2">
                                    {customerProfile.leadSource && (
                                        <div className="mt-2">
                                            <div>Lead source</div>
                                            <div className="font-family-semibold">{customerProfile.leadSource}</div>
                                        </div>
                                    )}
                                    {customerProfile.loginProvider && (
                                        <div className="mt-2">
                                            <div>Login provider</div>
                                            {customerProfile.loginProvider === "google.com" ? (
                                                <img
                                                    src={googleLoginIcon}
                                                    className="mt-1"
                                                    style={{ width: 24, height: 24 }}
                                                />
                                            ) : customerProfile.loginProvider === "facebook.com" ? (
                                                <img
                                                    src={facebookLoginIcon}
                                                    className="mt-1"
                                                    style={{ width: 24, height: 24 }}
                                                />
                                            ) : customerProfile.loginProvider === "apple.com" ? (
                                                <img
                                                    src={appleLoginIcon}
                                                    className="mt-1"
                                                    style={{ width: 24, height: 24 }}
                                                />
                                            ) : (
                                                <img
                                                    src={emailLoginIcon}
                                                    className="mt-1"
                                                    style={{ width: 24, height: 24 }}
                                                />
                                            )}
                                        </div>
                                    )}
                                    {customerProfile.rating && (
                                        <div className="mt-2">
                                            <div>Customer Rating</div>
                                            <div
                                                className={classNames(
                                                    "font-family-semibold rounded-circle d-flex align-items-center justify-content-center text-white",
                                                    {
                                                        "bg-primary": customerProfile.rating === "A",
                                                        "bg-tertiary2": customerProfile.rating === "B",
                                                        "bg-error": customerProfile.rating === "C",
                                                    }
                                                )}
                                                style={{ width: 24, height: 24, lineHeight: 1 }}>
                                                {customerProfile.rating}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 col-md-4 col-xl-3 mt-2 mt-xl-0 px-2 offset-md-4 offset-xl-0">
                                    {currentBill && (
                                        <div className="mt-2">
                                            <div>Late Payemnts</div>
                                            <div className="font-family-semibold">
                                                {customerProfile.totalLatePayments ?? "-"} Payments
                                            </div>
                                        </div>
                                    )}
                                    {currentBill &&
                                        !currentBill.billPaid &&
                                        currentBill.lastBillPaidDate &&
                                        hasOnePermission("billing.invoice.read") && (
                                            <div className="mt-2">
                                                <div>Last Payment</div>
                                                <div className="font-family-semibold">
                                                    ${currentBill.lastPaidAmount},{" "}
                                                    {format(new Date(currentBill.lastBillPaidDate), "MMMM dd, yyyy")}
                                                </div>
                                            </div>
                                        )}
                                    {currentBill && currentBill.billPaid && hasOnePermission("billing.invoice.read") && (
                                        <div className="mt-2">
                                            <div>Last Payment</div>
                                            <div className="font-family-semibold">
                                                ${currentBill.amountToPay},{" "}
                                                {format(new Date(currentBill.billPaidDate), "MMMM dd, yyyy")}
                                            </div>
                                        </div>
                                    )}
                                    {currentBill && hasOnePermission("billing.invoice.read") && (
                                        <div className="mt-2">
                                            <div>Current Bill Payment Status</div>
                                            <div className="font-family-semibold">
                                                {currentBill.billPaid
                                                    ? `Paid, ${format(
                                                          new Date(currentBill.billPaidDate),
                                                          "MMMM dd, yyyy"
                                                      )}`
                                                    : `Pending`}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {hasOnePermission("user_management.customer.update") && (
                                    <div
                                        className="reach-cs-edit cursor-pointer"
                                        style={{ position: "absolute", right: 16 }}
                                        onClick={() => navigate(`/edit-profile/${customerId}`)}
                                    />
                                )}
                            </div>
                        </Card>

                        <ActivationStatusCard {...customerProfile} className="mt-3" />

                        {customerProfile.isPrimary && <PaymentLinkCard customerProfile={customerProfile} />}

                        {((hasOnePermission("order_management.shipment.read") &&
                            customerProfile.shipmentOrder &&
                            customerProfile.status === CustomerStatus.INACTIVE) ||
                            (customerProfile.shipmentOrder &&
                                customerProfile.status === CustomerStatus.ACTIVE &&
                                customerProfile.shipmentOrder.orderStatus !== OrderStatus.DELIVERED &&
                                isAfter(
                                    new Date(customerProfile.shipmentOrder.orderDate),
                                    new Date(customerProfile.activationDate!)
                                ))) && (
                            <ShipmentOrderCard customerId={customerProfile.id} {...customerProfile.shipmentOrder} />
                        )}

                        <div className="row mt-3 mx-n2">
                            <div className="col-12 col-md-6 col-xl-4 px-2">
                                <ReachPlanCard {...customerProfile} />
                                {isPostActivationState(customerProfile.status) &&
                                    hasOnePermission("user_management.data_usage.read") && (
                                        <DataUsageCard {...customerProfile} />
                                    )}
                                {currentBill && !currentBill.billPaid && hasOnePermission("billing.invoice.read") && (
                                    <CurrentBillCard currentBill={currentBill} customerProfile={customerProfile} />
                                )}
                                <EstimateBillCard {...customerProfile} />
                                {currentBill && currentBill.totalBestFitSaveInDollar > 0 && (
                                    <Card className="mt-3">
                                        <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                                            All time Best Fit savings
                                        </div>
                                        <div className="d-flex align-items-center mt-3">
                                            <img src={bestFitIcon} width={32} className="mr-2" />
                                            <div className="font-family-bold" style={{ fontSize: 24 }}>
                                                ${currentBill.totalBestFitSaveInDollar.toFixed(2)}
                                            </div>
                                        </div>
                                    </Card>
                                )}
                                {customerProfile.isPrimary &&
                                    hasOnePermission("billing.invoice.read") &&
                                    width < ViewportBreakpoint.XL && (
                                        <PaymentHistoryCard customerId={customerProfile.id} />
                                    )}
                                {width < ViewportBreakpoint.XL &&
                                    customerProfile.isPrimary &&
                                    hasOnePermission("order_management.shipment.read") && (
                                        <OrderHistoryCard customerId={customerProfile.id} />
                                    )}
                                {width < ViewportBreakpoint.XL && (
                                    <CustomerActivityCard {...customerProfile} className="mt-3" />
                                )}
                                {width < ViewportBreakpoint.XL && <ToolsCard {...customerProfile} />}
                            </div>
                            {width >= ViewportBreakpoint.XL && (
                                <div className="col-xl-4 px-2">
                                    <CustomerActivityCard {...customerProfile} />
                                    <ToolsCard {...customerProfile} />
                                    <CustomerAccountInfo
                                        {...customerProfile}
                                        showRemoveCustomer={modalType === ModalType.RemoveCustomer}
                                        hideRemoveCustomer={() => setModalType(undefined)}
                                        onRecurringTopup={() =>
                                            hasOnePermission("user_management.topup.recurring") &&
                                            customerProfile.isPrimary
                                                ? setModalType(ModalType.RecurringTopup)
                                                : setModalType(ModalType.Primary)
                                        }
                                    />
                                    {customerProfile.isPrimary && hasOnePermission("billing.invoice.read") && (
                                        <PaymentHistoryCard customerId={customerProfile.id} />
                                    )}
                                    {customerProfile.isPrimary &&
                                        hasOnePermission("order_management.shipment.read") && (
                                            <OrderHistoryCard customerId={customerProfile.id} />
                                        )}
                                </div>
                            )}
                            <div className="col-12 col-md-6 col-xl-4 px-2 mt-3 mt-md-0">
                                <ManageLines
                                    customerProfile={customerProfile}
                                    onClick={(customer) => {
                                        navigate(`/profile/${customer.id}`);
                                    }}
                                    onClickActivate={(customer) => {
                                        if (!isCustomer(customer)) {
                                            // activate secondary customer from extraLines
                                            setModalType(ModalType.SecondaryLine);
                                        } else if (customer.isPrimary && !customer.isCardHold) {
                                            setModalType(ModalType.CardHold);
                                        } else {
                                            dispatch(
                                                customerSelectedForActivation({
                                                    customer: customer,
                                                    skipHold: !customer.isPrimary,
                                                })
                                            );
                                            navigate("/activation/scan-sim");
                                        }
                                    }}
                                />

                                <DeviceDetailsCard {...customerProfile} />

                                {width < ViewportBreakpoint.XL && (
                                    <CustomerAccountInfo
                                        {...customerProfile}
                                        showRemoveCustomer={modalType === ModalType.RemoveCustomer}
                                        hideRemoveCustomer={() => setModalType(undefined)}
                                        onRecurringTopup={() =>
                                            hasOnePermission("user_management.topup.recurring") &&
                                            customerProfile.isPrimary
                                                ? setModalType(ModalType.RecurringTopup)
                                                : setModalType(ModalType.Primary)
                                        }
                                    />
                                )}

                                {customerProfile.referralCode && hasOnePermission("billing.referral.read") && (
                                    <Card className="mt-3">
                                        <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                                            Referral
                                        </div>
                                        <div>Personal referral code:</div>
                                        <div
                                            className="font-family-semibold cursor-pointer mt-1"
                                            onClick={() =>
                                                customerProfile.referralCode &&
                                                navigator.clipboard.writeText(customerProfile.referralCode)
                                            }>
                                            {customerProfile.referralCode}
                                            <span className="reach-cs-copy ml-1 text-secondary" />
                                        </div>
                                        <div
                                            className="text-cta mt-1"
                                            style={{ fontSize: 12 }}
                                            onClick={() =>
                                                customerProfile.referralLink &&
                                                navigator.clipboard.writeText(customerProfile.referralLink)
                                            }>
                                            Copy link
                                        </div>
                                        <Button
                                            color="secondary"
                                            className="mt-2 col-10"
                                            onClick={() => setModalType(ModalType.ReferralCredit)}>
                                            View more
                                        </Button>
                                    </Card>
                                )}

                                <Card className="mt-3">
                                    <div className="font-family-bold align-self-start" style={{ fontSize: 16 }}>
                                        International services
                                    </div>
                                    <div
                                        className={classNames("w-100 d-flex py-3 cursor-pointer", {
                                            disabled:
                                                !hasOnePermission("user_management.intl_roaming.read") ||
                                                customerProfile.status !== CustomerStatus.ACTIVE,
                                        })}
                                        onClick={() =>
                                            hasOnePermission("user_management.intl_roaming.read") &&
                                            customerProfile.status === CustomerStatus.ACTIVE
                                                ? customerProfile.isPrimary
                                                    ? navigate(`/ir/manage/${customerId}`)
                                                    : setModalType(ModalType.Primary)
                                                : undefined
                                        }>
                                        <img src={internationalRoamingIcon} height={30} />
                                        <div className="flex-fill ml-2">
                                            <div className="font-family-semibold">International Roaming</div>
                                            <div style={{ fontSize: 12 }}>Stay connected while you travel</div>
                                        </div>
                                        <div
                                            className="reach-cs-arrow-right align-self-center"
                                            style={{ fontSize: 12 }}
                                        />
                                    </div>
                                    <div className="divider" />
                                    <div
                                        className={classNames("w-100 d-flex py-3 cursor-pointer", {
                                            disabled:
                                                !hasOnePermission("user_management.intl_calling.read") ||
                                                customerProfile.status !== CustomerStatus.ACTIVE,
                                        })}
                                        onClick={() =>
                                            hasOnePermission("user_management.intl_calling.read") &&
                                            customerProfile.status === CustomerStatus.ACTIVE
                                                ? customerProfile.isPrimary
                                                    ? navigate(`/ild/manage/${customerId}`)
                                                    : setModalType(ModalType.Primary)
                                                : undefined
                                        }>
                                        <img src={internationalCallingIcon} height={30} />
                                        <div className="flex-fill ml-2">
                                            <div className="font-family-semibold">International Calling</div>
                                            <div style={{ fontSize: 12 }}>Call &amp; text from the U.S.</div>
                                        </div>
                                        <div
                                            className="reach-cs-arrow-right align-self-center"
                                            style={{ fontSize: 12 }}
                                        />
                                    </div>
                                    <div className="divider" />
                                    <Link
                                        className="text-cta align-self-start mt-2"
                                        to={`/intl/rates/${customerProfile.id}`}>
                                        View rates{" "}
                                        <span className="reach-cs-arrow-right-solid ml-1" style={{ fontSize: 10 }} />
                                    </Link>
                                </Card>

                                {customerProfile.creditCards && hasOnePermission("billing.credit_card.read") && (
                                    <PaymentCards
                                        className="mt-3"
                                        cards={customerProfile.creditCards}
                                        customerId={customerProfile.id}
                                        isCardHold={customerProfile.isCardHold}
                                    />
                                )}

                                {hasOnePermission("user_management.helpdesk.read_ticket") && (
                                    <HelpdeskCard customerProfile={customerProfile} />
                                )}
                            </div>
                        </div>

                        {(customerProfile.isPrimary || isPostActivationState(customerProfile.status)) && (
                            <>
                                <h1 className="mt-4 mb-3">Quick Actions</h1>
                                <div className="row row-cols-1 row-cols-md-2">
                                    {isPostActivationState(customerProfile.status) && (
                                        <div className="col row row-cols-1 row-cols-xl-2">
                                            <div className="col">
                                                <div className="font-family-bold mb-3" style={{ fontSize: 16 }}>
                                                    Network
                                                </div>
                                                <div
                                                    className={classNames("text-cta", {
                                                        disabled:
                                                            !hasOnePermission("network.network_reset.allow") ||
                                                            customerProfile.operationsInProcess?.includes(
                                                                OperationsInProcess.RESET
                                                            ),
                                                    })}
                                                    onClick={() =>
                                                        hasOnePermission("network.network_reset.allow") &&
                                                        !customerProfile.operationsInProcess?.includes(
                                                            OperationsInProcess.RESET
                                                        ) &&
                                                        setModalType(ModalType.NetworkReset)
                                                    }>
                                                    <img
                                                        src={networkQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    Network reset
                                                </div>
                                                <div
                                                    className={classNames("text-cta mt-2", {
                                                        disabled: !hasOnePermission("network.simswap.create"),
                                                    })}
                                                    onClick={() =>
                                                        hasOnePermission("network.simswap.create") &&
                                                        setModalType(ModalType.SimSwap)
                                                    }>
                                                    <img
                                                        src={networkQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    SIM swap
                                                </div>
                                                {/* <div
                                                className={classNames("text-cta mt-2", {
                                                    disabled: !hasOnePermission("network.cdmaless.create"),
                                                })}
                                                onClick={() =>
                                                    hasOnePermission("network.cdmaless.create") &&
                                                    setModalType(ModalType.CDMAless)
                                                }>
                                                <img
                                                    src={networkQuickActionIcon}
                                                    style={{ height: 18 }}
                                                    className="mr-2"
                                                />
                                                CDMAless
                                            </div>
                                            <div
                                                className={classNames("text-cta mt-2", {
                                                    disabled: !hasOnePermission("network.cidb.allow"),
                                                })}
                                                onClick={() =>
                                                    hasOnePermission("network.cidb.allow") &&
                                                    setModalType(ModalType.CIDB)
                                                }>
                                                <img
                                                    src={networkQuickActionIcon}
                                                    style={{ height: 18 }}
                                                    className="mr-2"
                                                />
                                                Caller ID Block
                                            </div> */}
                                                <div className="mt-2">
                                                    <div
                                                        className={classNames("text-cta mt-2", {
                                                            disabled: !hasOnePermission(
                                                                "user_management.cdr_report.generate"
                                                            ),
                                                        })}
                                                        onClick={() =>
                                                            hasOnePermission("user_management.cdr_report.generate") &&
                                                            navigate(`/cdr-reports/${customerProfile.id}`)
                                                        }>
                                                        <img
                                                            src={networkQuickActionIcon}
                                                            style={{ height: 18 }}
                                                            className="mr-2"
                                                        />
                                                        Generate CDR Report
                                                    </div>
                                                </div>
                                                <div
                                                    className={classNames("text-cta mt-2", {
                                                        disabled: !hasOnePermission(
                                                            "network.voicemail_password_reset.allow"
                                                        ),
                                                    })}
                                                    onClick={() =>
                                                        hasOnePermission("network.voicemail_password_reset.allow") &&
                                                        setModalType(ModalType.Voicemail)
                                                    }>
                                                    <img
                                                        src={networkQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    Voicemail password reset
                                                </div>
                                            </div>

                                            <div className="col mt-3 mt-xl-0">
                                                <div className="font-family-bold mb-3" style={{ fontSize: 16 }}>
                                                    Data management
                                                </div>
                                                <div
                                                    className={classNames("text-cta", {
                                                        disabled: !hasOnePermission("user_management.topup.purchase"),
                                                    })}
                                                    onClick={() =>
                                                        hasOnePermission("user_management.topup.purchase") &&
                                                        customerProfile.isPrimary
                                                            ? setModalType(ModalType.AddTopup)
                                                            : setModalType(ModalType.Primary)
                                                    }>
                                                    <img
                                                        src={dataManagementQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    Add topup
                                                </div>
                                                <div
                                                    className={classNames("text-cta mt-2", {
                                                        disabled: !hasOnePermission(
                                                            "user_management.topup.cancel",
                                                            "user_management.topup.refund"
                                                        ),
                                                    })}
                                                    onClick={() =>
                                                        hasOnePermission(
                                                            "user_management.topup.cancel",
                                                            "user_management.topup.refund"
                                                        ) && customerProfile.isPrimary
                                                            ? setModalType(ModalType.RemoveTopup)
                                                            : setModalType(ModalType.Primary)
                                                    }>
                                                    <img
                                                        src={dataManagementQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    Remove topup
                                                </div>
                                                <div
                                                    className={classNames("text-cta mt-2", {
                                                        disabled: !hasOnePermission("user_management.topup.recurring"),
                                                    })}
                                                    onClick={() =>
                                                        hasOnePermission("user_management.topup.recurring") &&
                                                        customerProfile.isPrimary
                                                            ? setModalType(ModalType.RecurringTopup)
                                                            : setModalType(ModalType.Primary)
                                                    }>
                                                    <img
                                                        src={dataManagementQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    Recurring topup
                                                </div>
                                                <div
                                                    className={classNames("text-cta mt-2", {
                                                        disabled: !hasOnePermission("user_management.topup.read"),
                                                    })}
                                                    onClick={() =>
                                                        hasOnePermission("user_management.topup.read") &&
                                                        setModalType(ModalType.TopupHistory)
                                                    }>
                                                    <img
                                                        src={dataManagementQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    Topup history
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col row row-cols-1 row-cols-xl-2 mt-md-0">
                                        <div className="col mt-3 mt-md-0">
                                            <div className="font-family-bold mb-3" style={{ fontSize: 16 }}>
                                                User management
                                            </div>
                                            {/* <Link className="text-cta" to={`/manage-feature/${customerProfile.id}`}>
                                                    <img
                                                        src={userManagementQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    Manage features
                                                </Link> */}
                                            {isPostActivationState(customerProfile.status) && (
                                                <div
                                                    className={classNames("text-cta mt-2", {
                                                        disabled: !hasOnePermission("user_management.password.reset"),
                                                    })}
                                                    onClick={() =>
                                                        hasOnePermission("user_management.password.reset") &&
                                                        setModalType(ModalType.TemporaryPassword)
                                                    }>
                                                    <img
                                                        src={userManagementQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    Set temporary password
                                                </div>
                                            )}
                                            {isPostActivationState(customerProfile.status) && (
                                                <div
                                                    className={classNames("text-cta mt-2", {
                                                        disabled: !hasOnePermission(
                                                            "user_management.new_number_generation.create"
                                                        ),
                                                    })}
                                                    onClick={() =>
                                                        hasOnePermission(
                                                            "user_management.new_number_generation.create"
                                                        ) && setModalType(ModalType.NewNumber)
                                                    }>
                                                    <img
                                                        src={userManagementQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    New number generation
                                                </div>
                                            )}
                                            {isPostActivationState(customerProfile.status) &&
                                            customerProfile.isPrimary &&
                                            customerProfile.groupLines.some(
                                                (customer) =>
                                                    !customer.isPrimary && customer.status === CustomerStatus.ACTIVE
                                            ) &&
                                            hasOnePermission("user_management.account_swap.allow") ? (
                                                <div className="mt-2">
                                                    <Link
                                                        className="text-cta"
                                                        to={`/account-swap/${customerProfile.id}`}>
                                                        <img
                                                            src={userManagementQuickActionIcon}
                                                            style={{ height: 18 }}
                                                            className="mr-2"
                                                        />
                                                        Primary-secondary account swap
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className="text-cta mt-2 disabled">
                                                    <img
                                                        src={userManagementQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    Primary-secondary account swap
                                                </div>
                                            )}
                                            {isPostActivationState(customerProfile.status) &&
                                            hasOnePermission(
                                                "user_management.leaving_reach.disconnect",
                                                "user_management.leaving_reach.portout"
                                            ) ? (
                                                customerProfile.isPrimary ? (
                                                    <div className="mt-2">
                                                        <Link
                                                            className="text-cta"
                                                            to={`/disconnections/${customerProfile.id}`}>
                                                            <img
                                                                src={userManagementQuickActionIcon}
                                                                style={{ height: 18 }}
                                                                className="mr-2"
                                                            />
                                                            Disconnections
                                                        </Link>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="text-cta mt-2"
                                                        onClick={() => setModalType(ModalType.Primary)}>
                                                        <img
                                                            src={userManagementQuickActionIcon}
                                                            style={{ height: 18 }}
                                                            className="mr-2"
                                                        />
                                                        Disconnections
                                                    </div>
                                                )
                                            ) : (
                                                <div className="text-cta mt-2 disabled">
                                                    <img
                                                        src={userManagementQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    Disconnections
                                                </div>
                                            )}
                                            {isPostActivationState(customerProfile.status) &&
                                            hasOnePermission("user_management.mdn_alert.read") ? (
                                                <div className="mt-2">
                                                    <Link
                                                        className="text-cta"
                                                        to={`/alerts/history/${customerProfile.id}`}>
                                                        <img
                                                            src={userManagementQuickActionIcon}
                                                            style={{ height: 18 }}
                                                            className="mr-2"
                                                        />
                                                        Alerts history
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className="text-cta mt-2 disabled">
                                                    <img
                                                        src={userManagementQuickActionIcon}
                                                        style={{ height: 18 }}
                                                        className="mr-2"
                                                    />
                                                    Alerts history
                                                </div>
                                            )}
                                            <div className="mt-2">
                                                <img
                                                    src={userManagementQuickActionIcon}
                                                    style={{ height: 18 }}
                                                    className="mr-2"
                                                />
                                                <a
                                                    target="_blank"
                                                    className="text-cta"
                                                    rel="noreferrer"
                                                    href={`https://share.hsforms.com/1HuzE22qtSN6-sD_FT_IYQw2wsc2?email=${customerProfile.emailId}`}>
                                                    Send test agreement email
                                                </a>
                                            </div>
                                            <div className="mt-2">
                                                <img
                                                    src={userManagementQuickActionIcon}
                                                    style={{ height: 18 }}
                                                    className="mr-2"
                                                />
                                                <a
                                                    target="_blank"
                                                    className="text-cta"
                                                    rel="noreferrer"
                                                    href={`https://share.hsforms.com/1a4gaCrqwQgOI0BKpwGsfTQ2wsc2?email=${customerProfile.emailId}`}>
                                                    Unsubscribe to communication
                                                </a>
                                            </div>
                                            <div className="mt-2">
                                                <img
                                                    src={userManagementQuickActionIcon}
                                                    style={{ height: 18 }}
                                                    className="mr-2"
                                                />
                                                <a
                                                    target="_blank"
                                                    className="text-cta"
                                                    rel="noreferrer"
                                                    href={`https://share.hsforms.com/1lG0zFSy1SRS9lEBY5PzGFw2wsc2?email=${customerProfile.emailId}`}>
                                                    Bought device from Q1
                                                </a>
                                            </div>
                                        </div>

                                        {customerProfile.isPrimary && (
                                            <div className="col mt-3 mt-xl-0">
                                                <div className="font-family-bold mb-3" style={{ fontSize: 16 }}>
                                                    Billing &amp; Invoice
                                                </div>
                                                {hasOnePermission("billing.regenerate.allow") && (
                                                    <div
                                                        className={classNames("text-cta", {
                                                            disabled:
                                                                !currentBill ||
                                                                currentBill.billPaid ||
                                                                !hasOnePermission("billing.regenerate.allow"),
                                                        })}
                                                        onClick={() =>
                                                            currentBill &&
                                                            !currentBill.billPaid &&
                                                            hasOnePermission("billing.regenerate.allow") &&
                                                            setModalType(ModalType.RegenerateBill)
                                                        }>
                                                        <img
                                                            src={billingQuickActionIcon}
                                                            style={{ height: 18 }}
                                                            className="mr-2"
                                                        />
                                                        Regenerate bill
                                                    </div>
                                                )}
                                                {hasOnePermission("billing.waiveoff_late_payment.add") && (
                                                    <div
                                                        className={classNames("text-cta mt-2", {
                                                            disabled:
                                                                !currentBill ||
                                                                currentBill.billPaid ||
                                                                currentBill.waiveOffLatePaymentFees ||
                                                                !hasOnePermission("billing.waiveoff_late_payment.add"),
                                                        })}
                                                        onClick={() =>
                                                            currentBill &&
                                                            !currentBill.billPaid &&
                                                            !currentBill.waiveOffLatePaymentFees &&
                                                            hasOnePermission("billing.waiveoff_late_payment.add") &&
                                                            setModalType(ModalType.WaiveOffPayment)
                                                        }>
                                                        <img
                                                            src={billingQuickActionIcon}
                                                            style={{ height: 18 }}
                                                            className="mr-2"
                                                        />
                                                        Waive off late payment
                                                    </div>
                                                )}
                                                {hasOnePermission("billing.discount.read") && (
                                                    <div
                                                        className={classNames("text-cta mt-2", {
                                                            disabled: !hasOnePermission("billing.discount.read"),
                                                        })}
                                                        onClick={() =>
                                                            hasOnePermission("billing.discount.read") &&
                                                            navigate(`/discounts/${customerId}`)
                                                        }>
                                                        <img
                                                            src={billingQuickActionIcon}
                                                            style={{ height: 18 }}
                                                            className="mr-2"
                                                        />
                                                        Discounts
                                                    </div>
                                                )}
                                                {hasOnePermission("billing.addon_charge.allow") && (
                                                    <div
                                                        className={classNames("text-cta mt-2", {
                                                            disabled:
                                                                !hasOnePermission("billing.addon_charge.allow") ||
                                                                (customerProfile.status ===
                                                                    CustomerStatus.DISCONNECTED &&
                                                                    customerProfile.skipBilling),
                                                        })}
                                                        onClick={() =>
                                                            hasOnePermission("billing.addon_charge.allow") &&
                                                            !(
                                                                customerProfile.status ===
                                                                    CustomerStatus.DISCONNECTED &&
                                                                customerProfile.skipBilling
                                                            ) &&
                                                            setModalType(ModalType.AddonCharge)
                                                        }>
                                                        <img
                                                            src={billingQuickActionIcon}
                                                            style={{ height: 18 }}
                                                            className="mr-2"
                                                        />
                                                        Add on charges
                                                    </div>
                                                )}
                                                {hasOnePermission("billing.instant_charge.allow") && (
                                                    <div
                                                        className={classNames("text-cta mt-2", {
                                                            disabled: !hasOnePermission("billing.instant_charge.allow"),
                                                        })}
                                                        onClick={() =>
                                                            hasOnePermission("billing.instant_charge.allow") &&
                                                            setModalType(ModalType.InstantCharge)
                                                        }>
                                                        <img
                                                            src={billingQuickActionIcon}
                                                            style={{ height: 18 }}
                                                            className="mr-2"
                                                        />
                                                        Instant charge
                                                    </div>
                                                )}
                                                {hasOnePermission("billing.advance_payment.allow") && (
                                                    <div
                                                        className={classNames("text-cta mt-2", {
                                                            disabled: !hasOnePermission(
                                                                "billing.advance_payment.allow"
                                                            ),
                                                        })}
                                                        onClick={() =>
                                                            hasOnePermission("billing.advance_payment.allow") &&
                                                            setModalType(ModalType.PayInAdvance)
                                                        }>
                                                        <img
                                                            src={billingQuickActionIcon}
                                                            style={{ height: 18 }}
                                                            className="mr-2"
                                                        />
                                                        Pay in advance
                                                    </div>
                                                )}
                                                {hasOnePermission("billing.promise_to_pay.read_history") && (
                                                    <div
                                                        className={classNames("text-cta mt-2", {
                                                            disabled: !hasOnePermission(
                                                                "billing.promise_to_pay.read_history"
                                                            ),
                                                        })}
                                                        onClick={() =>
                                                            hasOnePermission("billing.promise_to_pay.read_history") &&
                                                            navigate(`/promisetopay/history/${customerId}`)
                                                        }>
                                                        <img
                                                            src={billingQuickActionIcon}
                                                            style={{ height: 18 }}
                                                            className="mr-2"
                                                        />
                                                        Promise to Pay History
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {!customerProfile.isPrimary && customerProfile.status === CustomerStatus.INACTIVE && (
                            <>
                                <h1 className="mt-4 mb-3">Quick Actions</h1>
                                <div className="row row-cols-1 row-cols-md-2">
                                    <div className="col row row-cols-1 row-cols-xl-2 mt-md-0">
                                        <div className="col mt-3 mt-md-0">
                                            <div className="font-family-bold mb-3" style={{ fontSize: 16 }}>
                                                User management
                                            </div>
                                            <div
                                                className={classNames("text-cta mt-2", {
                                                    disabled: !hasOnePermission("user_management.customer.delete"),
                                                })}
                                                onClick={() =>
                                                    hasOnePermission("user_management.customer.delete") &&
                                                    setModalType(ModalType.RemoveCustomer)
                                                }>
                                                <img
                                                    src={userManagementQuickActionIcon}
                                                    style={{ height: 18 }}
                                                    className="mr-2"
                                                />
                                                Remove customer
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
                {customerProfile && (
                    <CardHoldModal
                        show={modalType === ModalType.CardHold}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}
                {customerProfile && (
                    <LineInfoModal
                        show={modalType === ModalType.SecondaryLine}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}
                {customerProfile && modalType === ModalType.RecurringTopup && (
                    <RecurringTopupModal
                        show={modalType === ModalType.RecurringTopup}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.RemoveTopup && (
                    <RemoveTopupModal
                        title="Remove topup"
                        show={modalType === ModalType.RemoveTopup}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.AddTopup && (
                    <AddTopupModal
                        title="Add topup"
                        show={modalType === ModalType.AddTopup}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.SimSwap && (
                    <SimSwapModal
                        title="SIM Swap"
                        show={modalType === ModalType.SimSwap}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.NewNumber && (
                    <NewNumberModal
                        title="Generate new number"
                        show={modalType === ModalType.NewNumber}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.TopupHistory && (
                    <TopupHistoryModal
                        title="Data purchase history"
                        show={modalType === ModalType.TopupHistory}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.NetworkReset && (
                    <NetworkResetModal
                        title="Network reset"
                        show={modalType === ModalType.NetworkReset}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.RegenerateBill && (
                    <RegenerateBillModal
                        title="Regenerate bill"
                        show={modalType === ModalType.RegenerateBill}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.WaiveOffPayment && (
                    <WaiveOffPaymentModal
                        title="Waive-off late payment"
                        show={modalType === ModalType.WaiveOffPayment}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.ReferralCredit && (
                    <ReferralCreditModal
                        title="Referral credit"
                        show={modalType === ModalType.ReferralCredit}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.Voicemail && (
                    <VoicemailResetModal
                        title="Reset voicemail password"
                        show={modalType === ModalType.Voicemail}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.AddonCharge && (
                    <AddonChargeModal
                        title="Add on charges"
                        show={modalType === ModalType.AddonCharge}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.InstantCharge && (
                    <InstantChargeModal
                        title="Instant payment"
                        show={modalType === ModalType.InstantCharge}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.PayInAdvance && (
                    <PayInAdvanceModal
                        title="Pay in advance"
                        show={modalType === ModalType.PayInAdvance}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {customerProfile && modalType === ModalType.TemporaryPassword && (
                    <TemporaryPasswordModal
                        title="A random password will be sent to the email id"
                        show={modalType === ModalType.TemporaryPassword}
                        onHide={() => setModalType(undefined)}
                        customerProfile={customerProfile}
                    />
                )}

                {primaryCustomer && (
                    <Modal
                        title="Redirecting to primary user account!"
                        size="sm"
                        show={modalType === ModalType.Primary}
                        onHide={() => setModalType(undefined)}>
                        <div className="text-center">
                            In order to perform this action you need to do it via the primary account holder.
                        </div>
                        <Button
                            color="secondary"
                            className="mt-3"
                            fullWidth
                            onClick={() => {
                                navigate(`/profile/${primaryCustomer.id}`);
                                setModalType(undefined);
                            }}>
                            Next
                        </Button>
                    </Modal>
                )}
            </div>
        </Layout>
    );
}
